import React from 'react';
import DatePicker from 'react-datepicker';
import './css/datepicker.css';
import { fr } from 'date-fns/locale';

const CustomCalendar = ({ startDate, endDate, onChange, excludeDates, minDate }) => {
    return (
        <DatePicker
            selected={startDate}
            onChange={onChange}
            locale={fr}
            dateFormat="dd/MM/yyyy"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            excludeDates={excludeDates}
            minDate={minDate}
            inline
        />
    );
};

export default CustomCalendar;
