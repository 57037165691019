import React from 'react';
import LightBackground from '../../components/LightBackground';
import Banner from '../../components/Banner';
import Card from '../../components/Card';

/* Images */
import nightImage from '../../images/night.jpeg';
import fordImage4 from '../../images/4.jpg';

function Rent() {
	return (
		<div className="rent relative">
			<LightBackground />
			<div className="my-10">
				<Banner title="Location de Vans" description="Explorez nos options de location de vans et réservez votre véhicule aujourd'hui." image={nightImage} />
			</div>
			<div className="my-10">
				<Card title="Ford Nugget" description="Le Ford Nugget est un véhicule de luxe offrant un confort intégral. Parfait pour vos aventures, il est équipé de toutes les commodités nécessaires pour un voyage agréable et sans souci. Réservez dès maintenant et partez à la découverte de nouveaux horizons." image={fordImage4} link="/rent/ford_nugget" />
			</div>
		</div>
	);
}

export default Rent;
