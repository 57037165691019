import React from 'react';
import LightBackground from '../../components/LightBackground';
import Banner from '../../components/Banner';
import Selector from '../../components/Selector';
import Text from '../../components/Text';
import Galerie from '../../components/Galerie';
import Review from '../../components/Review';

/* Images */
import fordImage1 from '../../images/1.jpg';
import fordImage2 from '../../images/2.jpg';
import fordImage3 from '../../images/3.jpg';
import fordImage4 from '../../images/4.jpg';
import fordImage5 from '../../images/5.jpg';
import fordImage6 from '../../images/6.jpg';
import fordImage7 from '../../images/7.jpg';
import fordImage8 from '../../images/8.jpg';
import fordImage9 from '../../images/9.jpg';
import fordImage10 from '../../images/10.jpg';
import nightImage from '../../images/night.jpeg';

function Home() {
    return (
        <div className="home relative">
            <LightBackground />
            <div className="my-10">
                <Banner title="Bienvenue chez C' Location" description="Découvrez notre sélection de vans aménagés pour des aventures sans limites" buttonText="Réserver" buttonLink="/rent" image={nightImage} />
            </div>
            <div className="my-10">
                <Selector items={[
                    {title: 'Ford Nugget', image: fordImage4, description: 'Véhicule de luxe avec un confort intégral', link: '/rent/ford_nugget'},
                ]} />
            </div>
            <div className="my-10">
                <Text text="Bienvenue sur notre site dédié à la location de vans aménagés pour des aventures sans limites.
                            Découvrez la liberté de voyager à votre rythme avec nos véhicules confortables et polyvalents, équipés pour répondre à tous vos besoins.
                            Profitez d'un aménagement intelligent, d'une connectivité moderne et de tout le confort nécessaire pour vous sentir chez vous ou que vous soyez.
                            Préparez-vous à explorer de nouveaux horizons avec nos vans, conçus pour vous offrir une expérience de voyage inoubliable, que ce soit pour un week-end ou une longue escapade.
                            Embarquez pour l'aventure sans compromis avec notre service de location de vans aménagés.
                            Notre entreprise est située à Chartres en Eure-et-Loir et est à 50 minutes de Paris"
                />
            </div>
            <div className="my-10">
                <Galerie images={[fordImage1, fordImage10, fordImage3, fordImage4, fordImage5, fordImage6, fordImage7, fordImage8, fordImage9, fordImage2]} />
            </div>
            <div className="my-10">
                <Review />
            </div>
        </div>
    );
}

export default Home;
