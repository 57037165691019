import { useState, useEffect } from 'react';

export function useIsMobileView() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobileView;
}

export function useIsMobileViewStrict() {
  const [isMobileViewStrict, setIsMobileViewStrict] = useState(window.innerWidth <= 1370);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileViewStrict(window.innerWidth <= 1370);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobileViewStrict;
}
