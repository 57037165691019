import React from 'react';
import { Link } from 'react-router-dom';
import { useIsMobileView } from '../hooks/useIsMobileView';

function Banner({title, description, buttonText=null, buttonLink=null, image}) {
	const isMobileView = useIsMobileView();

	return (
		<div className="max-w-[1920px] mx-auto">
			<div className={`banner relative ${!isMobileView ? "mx-10" : "m-3 mt-10"}`}>
				<img src={image} alt="Banner" className={`w-full h-auto object-cover rounded-lg ${isMobileView ? "relative h-64" : "relative"}`} />
				<div className={`flex flex-col items-center justify-center ${isMobileView ? "mt-10 w-full" : "absolute inset-0 bg-opacity-50"}`}>
					<div className={`${isMobileView ? "w-full" : "w-1/2 max-w-[500px]"} flex flex-col items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-lg p-5 rounded-lg pb-8`}>
						<h2 className="text-3xl font-semibold p-5 text-center text-white">{title}</h2>
						<p className={`text-lg text-center text-white ${buttonText && "mb-5"}`}>{description}</p>
						{buttonText && (
							<Link to={buttonLink} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 text-lg" style={{ fontSize: '1rem' }}>
								{buttonText}
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Banner;
