import React from 'react';
import { Link } from 'react-router-dom';
import { useIsMobileView } from '../hooks/useIsMobileView';

function Card({ title, description, image, link }) {
    const isMobileView = useIsMobileView();

    return (
        <div className="max-w-[1920px] mx-auto">
            <Link to={link}>
                <div className={`flex ${!isMobileView ? "mx-10 p-10 gap-10 flex-row" : "m-3 mt-10 p-3 gap-3 flex-col"} items-center rounded-lg bg-gray-90 text-white py-8 bg-gray-900 bg-opacity-50 backdrop-blur-lg overflow-hidden transition-all duration-300 ease-in-out transform hover:scale-105`}>
                    <div className={`relative ${!isMobileView ? "w-1/4" : "w-full"} h-[200px] overflow-hidden rounded-lg flex items-center justify-center min-w-[230px]`}>
                        <img src={image} alt={title} className="max-w-full max-h-full object-contain rounded-lg" />
                    </div>
                    <div className="w-3/4 px-6 py-4 flex flex-col mx-auto justify-center">
                        <div className="font-bold text-xl mb-2">
                            {title}
                        </div>
                        <p className="text-gray-300 text-base">
                            {description}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default Card;
