import React from 'react';
import { useIsMobileView } from '../hooks/useIsMobileView';

function Text({ text }) {
	const formattedText = text.split('.').map((sentence, index) => (
		<li key={index} className="pb-3">{sentence.trim()}.</li>
	));

	const isMobileView = useIsMobileView();

	return (
		<div className="max-w-[1920px] mx-auto text-white">
			<div className={`selector ${isMobileView ? 'm-3 p-5' : 'm-10 p-10 my-0'} rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg flex-row flex justify-center items-center`} tabIndex="0">
				<ul className={`max-w-[1000px] w-full ${isMobileView ? 'p-0 m-0' : 'px-16 p-4 pb-1 m-4'} h-full overflow-y-auto`} style={{ fontSize: '1rem' }}>
					{formattedText}
				</ul>
			</div>
		</div>
	);
}

export default Text;
