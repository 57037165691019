import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useIsMobileView } from '../hooks/useIsMobileView';

function Contact() {
    const isMobileView = useIsMobileView();

    return (
        <div className="contact max-w-[1920px] mx-auto">
            <div className={`${isMobileView ? 'm-3 p-3 mt-10' : 'mx-10 p-10'} flex flex-col items-center rounded-lg bg-gray-9000 text-white py-8 bg-gray-900 bg-opacity-50 backdrop-blur-lg`}>
                <div className={`flex ${isMobileView ? 'flex-col gap-10' : 'flex-row gap-64'} justify-center`}>
                    <ul className="flex flex-col gap-2 items-center">
                        <h2 className="text-lg font-semibold mb-4 text-center">Suivez-nous</h2>
                        <li>
                            <a href="https://www.instagram.com/clocationvan" target="_blank" rel="noopener noreferrer" className="text-sm hover:underline flex items-center">
                                Instagram <img src="/images/instagram.png" alt="Instagram" className="ml-2 max-h-10 max-w-10 rounded" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.leboncoin.fr/votrecompte" target="_blank" rel="noopener noreferrer" className="text-sm hover:underline flex items-center">
                                Leboncoin <img src="/images/leboncoin.png" alt="Leboncoin" className="ml-2 max-h-10 max-w-10 rounded" />
                            </a>
                        </li>
                    </ul>
                    <ul className="items-center">
                        <h2 className="text-lg font-semibold mb-4 text-center">Contactez-nous</h2>
                        <div className="flex flex-col gap-2 justify-center">
                            <li>
                                <a href="mailto:contact@c-location.fr" className="text-sm hover:underline flex items-center">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> Email : contact@c-location.fr
                                </a>
                            </li>
                            <li className="text-sm flex items-center">
                                <FontAwesomeIcon icon={faPhone} className="mr-2" /> Numéro de téléphone : 06 31 88 49 43
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Contact;
