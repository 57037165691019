import React from 'react';
import { useIsMobileView } from '../hooks/useIsMobileView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PictoBar = ({ pictos }) => {
	const isMobileView = useIsMobileView();

	return (
		<div className="max-w-[1920px] mx-auto">
			<div className={`flex ${!isMobileView ? "mx-10 p-10 gap-10 flex-row" : "m-3 p-5 gap-10 flex-col"} justify-start items-center overflow-x-auto rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg`}>
				{pictos.map((picto, index) => (
					<div key={index} className="flex flex-col items-center mx-4 min-w-[100px]">
						<div className="w-10 h-10 mb-2">
							<FontAwesomeIcon icon={picto.icon} className="w-full h-full object-contain text-white" />
						</div>
						<p className="text-white text-sm text-center">{picto.text}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default PictoBar;
