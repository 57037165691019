import React, { useState, useEffect } from 'react';
import CustomCalendar from './CustomCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faChevronDown, faChevronUp, faGears, faShield, faPlus, faMinus, faGauge, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useIsMobileView } from '../hooks/useIsMobileView';
import SimPrice from './SimPrice';
import './css/lever.css';

function getCookie(name) {
    let cookieValue = null;

    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();

            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function Calendar() {
    // Mobile view state
    const isMobileView = useIsMobileView();

    // Date states
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [unavailableDates] = useState([
        new Date('2024/08/04'),
        new Date('2024/08/05'),
        new Date('2024/08/06'),
        new Date('2024/08/07'),
        new Date('2024/08/08'),
        new Date('2024/08/09'),
        new Date('2024/08/10'),
    ]);
    const [warningMessage, setWarningMessage] = useState('');

    // Kilometer states
    const pricePerDay = 119;
    const kmAllowedPerDay = 250;
    const kmPricePerKm = 0.30;

    const [extraKm, setExtraKm] = useState(0);
    const [extraKmPrice, setExtraKmPrice] = useState(0);
    const [totalKm, setTotalKm] = useState(0);
    const [customExtraKm, setCustomExtraKm] = useState(0);
    const [diffDays, setDiffDays] = useState(0);
    const [extraKmMenuOpen, setExtraKmMenuOpen] = useState(false);

    // Equipment states
    const [equipmentBed, setEquipmentBed] = useState(0);
    const minEquipmentBed = 0;
    const maxEquipmentBed = 2;
    const equipmentBedPrice = 15;
    const [equipmentCale, setEquipmentCale] = useState(false);
    const equipmentCalePrice = 10;
    const [equipmentCabine, setEquipmentCabine] = useState(false);
    const equipmentCabinePrice = 15;
    const [equipmentMenage, setEquipmentMenage] = useState(false);
    const equipmentMenagePrice = 50;

    // Assurance states
    const [selectedAssurance, setSelectedAssurance] = useState('base');
    const assuranceIntermediatePrice = 20;
    const assuranceCompletePrice = 45;

    // Total price states
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        setExtraKmPrice(((isNaN(Number(extraKm)) ? 0 : Number(extraKm)) + (extraKm === 'custom' ? customExtraKm : 0)) * kmPricePerKm);
    }, [extraKm, kmPricePerKm, customExtraKm]);

    useEffect(() => {
        updateTotalPrice(diffDays);
    }, [diffDays, extraKmPrice, equipmentBed, equipmentCale, equipmentCabine, equipmentMenage, selectedAssurance]);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        const isDateExcluded = (date) => unavailableDates.some(unavailableDate => unavailableDate.getTime() === date.getTime());

        if (start && end) {
            let currentDate = new Date(start);
            let hasExcludedDates = false;

            while (currentDate <= end) {
                if (isDateExcluded(currentDate)) {
                    hasExcludedDates = true;
                    break;
                }
                currentDate.setDate(currentDate.getDate() + 1);
            }

            if (hasExcludedDates) {
                setWarningMessage('Votre sélection contient des jours exclus.');
            } else {
                setWarningMessage('');
            }

            setStartDate(start);
            setEndDate(end);

            const diffTime = Math.abs(end - start);
            const calculatedDiffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            setDiffDays(calculatedDiffDays);
            setTotalKm(calculatedDiffDays * kmAllowedPerDay);
        } else {
            setStartDate(start);
            setEndDate(end);
            setWarningMessage('');
            setDiffDays(0);
            setTotalKm(0);
            setTotalPrice(0);
        }
    };

    const handleConfirm = () => {
        if (startDate && endDate) {
            const diffTime = Math.abs(endDate - startDate);
            setDiffDays(Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1);

            const popup = document.createElement('div');
            popup.className = `fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-lg z-50`;
            popup.innerHTML = `
                <div class="rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-lg p-6 max-w-[700px] w-full mx-4 text-white">
                    <h2 class="text-2xl font-bold mb-4 text-center">Confirmation</h2>
                    <p class="mb-4">Vous avez sélectionné les dates suivantes :</p>
                    <p class="mb-4 text-center text-blue-500">${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}.</p>
                    <p class="mb-4">Le prix de votre réservation sera de :</p>
                    <p class="mb-4 text-center text-blue-500">${totalPrice} €.
                        <br /><span class="text-white">Une caution de 2000€ sera demandée avec une carte de crédit ou chèque bancaire.</span>
                    </p>
                    <p class="mb-4">Veuillez entrer vos informations pour confirmer.</p>
                    <div class="flex mb-4">
                        <input type="text" id="firstName" class="w-1/2 p-2 border border-gray-300 rounded-l bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Prénom" />
                        <input type="text" id="lastName" class="w-1/2 p-2 border border-gray-300 rounded-r bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Nom" />
                    </div>
                    <input type="email" id="email" class="w-full p-2 border border-gray-300 rounded mb-4 bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Votre email" />
                    <input type="tel" id="phone" class="w-full p-2 border border-gray-300 rounded bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Votre numéro de téléphone" />
                    <div class="flex items-center mb-4">
                        <input type="checkbox" id="consent" class="mr-2 p-2 rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg" />
                        <label for="consent" class="p-2">J'accepte d'être contacté et que mes données soient gardées chez C' Loc.</label>
                    </div>
                    <div id="errorMessages" class="text-red-500 mb-4"></div>
                    <div class="flex justify-end">
                        <button id="cancelButton" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2">Annuler</button>
                        <button id="confirmButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Confirmer</button>
                    </div>
                    <div id="loadingAnimation" class="hidden mt-4">
                        <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
                        <p class="text-center mt-2">Chargement en cours...</p>
                    </div>
                </div>
            `;

            document.body.appendChild(popup);

            document.getElementById('cancelButton').addEventListener('click', () => {
                document.body.removeChild(popup);
            });

            document.getElementById('confirmButton').addEventListener('click', () => {
                const firstName = document.getElementById('firstName').value;
                const lastName = document.getElementById('lastName').value;
                const email = document.getElementById('email').value;
                const phone = document.getElementById('phone').value;
                const consent = document.getElementById('consent').checked;

                const firstNameRegex = /^[a-zA-Z]{2,30}$/;
                const lastNameRegex = /^[a-zA-Z]{2,30}$/;
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const phoneRegex = /^[0-9]{10}$/;

                if (!firstName || !lastName || !email || !phone || !consent) {
                    document.getElementById('errorMessages').innerText = 'Veuillez remplir tous les champs et accepter les conditions.';
                    return;
                }

                if (!firstNameRegex.test(firstName)) {
                    document.getElementById('errorMessages').innerText = 'Le prénom doit contenir entre 2 et 30 caractères.';
                    return;
                }

                if (!lastNameRegex.test(lastName)) {
                    document.getElementById('errorMessages').innerText = 'Le nom doit contenir entre 2 et 30 caractères.';
                    return;
                }

                if (!emailRegex.test(email)) {
                    document.getElementById('errorMessages').innerText = 'L\'email n\'est pas valide.';
                    return;
                }

                if (!phoneRegex.test(phone)) {
                    document.getElementById('errorMessages').innerText = 'Le numéro de téléphone n\'est pas valide.';
                    return;
                }

                const reservationDates = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;

                const csrftoken = getCookie('csrftoken');

                fetch('/forms/mail/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrftoken,
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        reservation_dates: reservationDates,
                        phone: phone,
                        price: totalPrice,
                        equipmentBed: equipmentBed,
                        equipmentCabine: equipmentCabine,
                        equipmentCale: equipmentCale,
                        equipmentMenage: equipmentMenage,
                        assurance: selectedAssurance,
                        extraKm: extraKm,
                        customExtraKm: customExtraKm,
                    })
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        const successPopup = document.createElement('div');
                        successPopup.className = `fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-lg z-50`;
                        successPopup.innerHTML = `
                            <div class="rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-lg p-6 max-w-[700px] w-full mx-4 text-white">
                                <h2 class="text-2xl font-bold mb-4">Succès</h2>
                                <p class="mb-4">Données envoyées avec succès !</p>
                                <div class="flex justify-end">
                                    <button id="closeSuccessPopup" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Fermer</button>
                                </div>
                            </div>
                        `;
                        document.body.appendChild(successPopup);

                        document.getElementById('closeSuccessPopup').addEventListener('click', () => {
                            document.body.removeChild(successPopup);
                        });

                        document.body.removeChild(popup);
                    } else {
                        document.getElementById('errorMessages').innerText = data.error || 'Une erreur est survenue. Veuillez réessayer.';
                    }
                })
                .catch(error => {
                    if (error.errors && error.errors.email) {
                        document.getElementById('errorMessages').innerText = error.errors.email;
                    } else {
                        document.getElementById('errorMessages').innerText = 'Une erreur est survenue. Veuillez réessayer.';
                    }
                    console.error('Erreur lors de l\'envoi du mail :', error);
                });
            });
        } else {
            setWarningMessage('Veuillez sélectionner une date.');
        }
    };

    const updateTotalPrice = (calculatedDiffDays) => {
        const extraKmCost = isNaN(Number(extraKmPrice)) ? 0 : Number(extraKmPrice);
        const assuranceCost = (selectedAssurance === "intermediaire" ? assuranceIntermediatePrice : (selectedAssurance === "complete" ? assuranceCompletePrice : 0)) * calculatedDiffDays;
        const equipmentCost = (equipmentMenage ? equipmentMenagePrice : 0) + (equipmentCabine ? equipmentCabinePrice : 0) + (equipmentCale ? equipmentCalePrice : 0) + ((equipmentBed ? equipmentBedPrice : 0) * equipmentBed);
        const total = calculatedDiffDays * pricePerDay + extraKmCost + assuranceCost + equipmentCost;
        setTotalPrice(total);
    };

    return (
        <div>
            <div className={`calendar-container max-w-[1920px] mx-auto ${isMobileView ? 'px-3' : 'px-10'} relative z-10 w-full ${isMobileView ? 'pb-8' : 'pb-20'}`}>
                <div className="glassmorphism p-10 rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg text-white relative z-10">
                    <h2 className="text-2xl font-bold mb-4">Dates <FontAwesomeIcon icon={faCalendar} className="ml-2" /></h2>
                    <div className={`flex ${isMobileView ? 'flex-col gap-10 items-center' : 'flex-row gap-16 justify-center'}  `}>
                        <div className="max-w-[240.3px]">
                            <CustomCalendar
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleDateChange}
                                excludeDates={unavailableDates}
                                minDate={new Date()}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`calendar-container max-w-[1920px] mx-auto ${isMobileView ? 'px-3' : 'px-10'} relative z-10 w-full ${isMobileView ? 'pb-8' : 'pb-20'}`}>
                <div className="glassmorphism p-10 rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg text-white relative z-10">
                    <h2 className="text-2xl font-bold mb-4">Kilométrage <FontAwesomeIcon icon={faGauge} className="ml-2" /></h2>
                    <div className="my-auto">
                        <div className={` ${isMobileView ? 'flex flex-col gap-16' : 'grid gap-8'} grid-cols-3 rounded-lg border border-gray-700 p-8 w-full`} style={{gridTemplateColumns: '1fr 1fr 1fr'}}>
                            <div className="flex flex-col gap-2 justify-center">
                                <h4 className="font-bold text-center">Kilomètres inclus</h4>
                                <p className="font-bold text-2xl text-center">{totalKm} km</p>
                                <p className="text-center">Soit {kmAllowedPerDay} km par jour de location</p>
                            </div>
                            <div className="flex flex-col gap-2 justify-center relative z-50">
                                <h4 className="font-bold text-center">Kilomètres inclus</h4>
                                <div className="relative">
                                    <button className="w-full p-2 border border-gray-700 rounded text-white" onClick={() => setExtraKmMenuOpen(!extraKmMenuOpen)}>
                                        {extraKm > 0 ? extraKm + ' km' : 'Sélectionner les kilomètres supplémentaires'} {extraKmMenuOpen ? <FontAwesomeIcon icon={faChevronUp} className="ml-4" /> : <FontAwesomeIcon icon={faChevronDown} className="ml-4" />}
                                    </button>
                                    <div id="extraKmMenu" className={`absolute w-full bg-gray-900 bg-opacity-50 backdrop-blur-lg text-white mt-2 rounded-lg border border-gray-700 ${extraKmMenuOpen ? 'block' : 'hidden'} z-50`}>
                                        <div className={`p-2 ${extraKm === 0 ? 'bg-blue-500' : ''}`}        onClick={() => setExtraKm(0)}>0 km (0 €)</div>
                                        <div className={`p-2 ${extraKm === 100 ? 'bg-blue-500' : ''}`}      onClick={() => setExtraKm(100)}>100 km (30 €)</div>
                                        <div className={`p-2 ${extraKm === 200 ? 'bg-blue-500' : ''}`}      onClick={() => setExtraKm(200)}>200 km (60 €)</div>
                                        <div className={`p-2 ${extraKm === 300 ? 'bg-blue-500' : ''}`}      onClick={() => setExtraKm(300)}>300 km (90 €)</div>
                                        <div className={`p-2 ${extraKm === 400 ? 'bg-blue-500' : ''}`}      onClick={() => setExtraKm(400)}>400 km (120 €)</div>
                                        <div className={`p-2 ${extraKm === 500 ? 'bg-blue-500' : ''}`}      onClick={() => setExtraKm(500)}>500 km (150 €)</div>
                                        <div className={`p-2 ${extraKm === 750 ? 'bg-blue-500' : ''}`}      onClick={() => setExtraKm(750)}>750 km (225 €)</div>
                                        <div className={`p-2 ${extraKm === 1000 ? 'bg-blue-500' : ''}`}     onClick={() => setExtraKm(1000)}>1000 km (300 €)</div>
                                        <div className={`p-2 ${extraKm === 1500 ? 'bg-blue-500' : ''}`}     onClick={() => setExtraKm(1500)}>1500 km (450 €)</div>
                                        <div className={`p-2 ${extraKm === 2000 ? 'bg-blue-500' : ''}`}     onClick={() => setExtraKm(2000)}>2000 km (600 €)</div>
                                        <div className={`p-2 ${extraKm === 'custom' ? 'bg-blue-500' : ''}`} onClick={() => setExtraKm('custom')}>Personnalisé</div>
                                    </div>
                                </div>
                                {extraKm === 'custom' && (
                                    <div className="relative">
                                        <input
                                            type="number"
                                            id="customExtraKm"
                                            className="w-full p-2 border border-gray-700 rounded bg-gray-900 bg-opacity-0 backdrop-blur-lg text-white mt-2 appearance-none"
                                            placeholder="Entrez les kilomètres supplémentaires"
                                            value={customExtraKm}
                                            min="100"
                                            max="2000"
                                            onChange={(e) => setCustomExtraKm(Number(e.target.value))}
                                        />
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                    </div>
                                )}
                                <p className="text-center">Soit 0.30 € par km</p>
                            </div>
                            <div>
                                <h4 className="font-bold text-center">Kilomètres totaux</h4>
                                <p className="font-bold text-2xl text-center">{totalKm + (isNaN(Number(extraKm)) ? 0 : Number(extraKm)) + (extraKm === 'custom' ? customExtraKm : 0)} km</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`calendar-container max-w-[1920px] mx-auto ${isMobileView ? 'px-3' : 'px-10'} w-full ${isMobileView ? 'pb-8' : 'pb-20'}`}>
                <div className="glassmorphism p-10 rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg text-white">
                    <h2 className="text-2xl font-bold mb-4">Assurance <FontAwesomeIcon icon={faShield} className="ml-2" /></h2>
                    <div className={`flex ${isMobileView ? 'flex-col gap-10 items-center' : 'flex-row gap-16 justify-center'}  `}>
                        <div className={`${isMobileView ? 'flex flex-col gap-16' : 'grid gap-8'} grid-cols-3 rounded-lg border p-8 w-full gap-8 ${selectedAssurance === 'base' ? 'border-blue-500' : 'border-gray-700'}`} style={{gridTemplateColumns: '7fr 1fr'}} onClick={() => setSelectedAssurance('base')}>
                            <div className="flex flex-col gap-2 justify-center">
                                <h4 className="font-bold">Assurance de base</h4>
                                <p>Protection minimale pour votre voyage.</p>
                            </div>
                            <p className="font-bold self-center">0 €</p>
                        </div>
                        <div className={`${isMobileView ? 'flex flex-col gap-16' : 'grid gap-8'} grid-cols-3 rounded-lg border p-8 w-full gap-8 ${selectedAssurance === 'intermediaire' ? 'border-blue-500' : 'border-gray-700'}`} style={{gridTemplateColumns: '7fr 1fr'}} onClick={() => setSelectedAssurance('intermediaire')}>
                            <div className="flex flex-col gap-2 justify-center">
                                <h4 className="font-bold">Assurance intermédiaire</h4>
                                <p>Protection étendue pour plus de sérénité.</p>
                            </div>
                            <p className="font-bold self-center">{assuranceIntermediatePrice} € / jour</p>
                        </div>
                        <div className={`${isMobileView ? 'flex flex-col gap-16' : 'grid gap-8'} grid-cols-3 rounded-lg border p-8 w-full gap-8 ${selectedAssurance === 'complete' ? 'border-blue-500' : 'border-gray-700'}`} style={{gridTemplateColumns: '7fr 1fr'}} onClick={() => setSelectedAssurance('complete')}>
                            <div className="flex flex-col gap-2 justify-center">
                                <h4 className="font-bold">Assurance complète</h4>
                                <p>Protection maximale pour un voyage sans souci.</p>
                            </div>
                            <p className="font-bold self-center">{assuranceCompletePrice} € / jour</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`calendar-container max-w-[1920px] mx-auto ${isMobileView ? 'px-3' : 'px-10'} relative z-10 w-full ${isMobileView ? 'pb-8' : 'pb-20'}`}>
                <div className="glassmorphism p-10 rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg text-white relative z-10">
                    <h2 className="text-2xl font-bold mb-8">Équipement <FontAwesomeIcon icon={faGears} className="ml-2" /></h2>
                    <div className="flex flex-col gap-8">
                        <div className={`${isMobileView ? 'flex flex-col gap-8' : 'grid gap-8'} grid-cols-3 rounded-lg border border-gray-700 p-8 w-full`} style={{gridTemplateColumns: '7fr 1fr 1fr'}}>
                            <div className="flex flex-col gap-2 justify-center">
                                <h4 className="font-bold">Forfait de ménage</h4>
                                <p>Forfait de ménage pour le véhicule. <br/>Attention si on considère que le véhicule est trop sale un supplément peut etre prélevé sur la caution, soyez respectueux de l'équipe de ménage.</p>
                            </div>
                            <p className="font-bold self-center">50 €</p>
                            <label className="flex items-center justify-center">
                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                    <input type="checkbox" name="menage-lever" id="menage-lever" className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out transform" checked={equipmentMenage} onChange={() => setEquipmentMenage(!equipmentMenage)} />
                                    <label htmlFor="menage-lever" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer transition-colors duration-200 ease-in-out"></label>
                                </div>
                            </label>
                        </div>

                        <div className={`${isMobileView ? 'flex flex-col gap-8' : 'grid gap-8'} grid-cols-3 rounded-lg border border-gray-700 p-8 w-full`} style={{gridTemplateColumns: '7fr 1fr 1fr'}}>
                            <div className="flex flex-col gap-2 justify-center">
                                <h4 className="font-bold">Cabine de douche portable de camping</h4>
                                <p>Idéale pour se doucher en plein air lors de vos aventures en camping.</p>
                            </div>
                            <p className="font-bold self-center">15 €</p>
                            <label className="flex items-center justify-center">
                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                    <input type="checkbox" name="cabine-lever" id="cabine-lever" className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out transform" checked={equipmentCabine} onChange={() => setEquipmentCabine(!equipmentCabine)} />
                                    <label htmlFor="cabine-lever" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer transition-colors duration-200 ease-in-out"></label>
                                </div>
                            </label>
                        </div>

                        <div className={`${isMobileView ? 'flex flex-col gap-8' : 'grid gap-8'} grid-cols-3 rounded-lg border border-gray-700 p-8 w-full`} style={{gridTemplateColumns: '7fr 1fr 1fr'}}>
                            <div className="flex flex-col gap-2 justify-center">
                                <h4 className="font-bold">Cales de niveau</h4>
                                <p>2 cales de niveau, parfaites pour stabiliser votre véhicule sur un terrain inégal.</p>
                            </div>
                            <p className="font-bold self-center">10 €</p>
                            <label className="flex items-center justify-center">
                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                    <input type="checkbox" name="equipecale-lever" id="cale-lever" className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-200 ease-in-out transform" checked={equipmentCale} onChange={() => setEquipmentCale(!equipmentCale)} />
                                    <label htmlFor="cale-lever" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer transition-colors duration-200 ease-in-out"></label>
                                </div>
                            </label>
                        </div>

                        <div className={`${isMobileView ? 'flex flex-col gap-8' : 'grid gap-8'} grid-cols-3 rounded-lg border border-gray-700 p-8 w-full`} style={{gridTemplateColumns: '7fr 1fr 1fr'}}>
                            <div className="flex flex-col gap-2 justify-center">
                                <h4 className="font-bold">Literie 2 pers</h4>
                                <p>Literie confortable pour 2 personnes, parfaite pour une bonne nuit de sommeil lors de vos aventures en camping. <br/>Comprend un surmatela, deux oreillers, un drap et une couette.</p>
                            </div>
                            <p className="font-bold self-center">15 €</p>
                            <label className="flex items-center justify-center">
                                <div className="flex items-center">
                                    <button className="bg-gray-500 text-white py-2 px-2.5 rounded-full font-bold text-xs" onClick={() => setEquipmentBed(Math.max(equipmentBed - 1, minEquipmentBed))}><FontAwesomeIcon icon={faMinus} /></button>
                                    <p className="w-12 text-center">{equipmentBed}</p>
                                    <button className="bg-gray-500 text-white py-2 px-2.5 rounded-full font-bold text-xs" onClick={() => setEquipmentBed(Math.min(equipmentBed + 1, maxEquipmentBed))}><FontAwesomeIcon icon={faPlus} /></button>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${isMobileView ? 'pb-8' : 'pb-20'}`}>
                <SimPrice diffDays={diffDays}
                          price={pricePerDay}
                          extraKm={isNaN(Number(extraKm)) ? customExtraKm : Number(extraKm)}
                          extraKmPrice={extraKmPrice}
                          equipmentBed={equipmentBed}
                          equipmentCabine={equipmentCabine}
                          equipmentCale={equipmentCale}
                          equipmentMenage={equipmentMenage}
                          equipmentCalePrice={equipmentCalePrice}
                          equipmentCabinePrice={equipmentCabinePrice}
                          equipmentMenagePrice={equipmentMenagePrice}
                          selectedAssurance={selectedAssurance}
                          assuranceIntermediatePrice={assuranceIntermediatePrice}
                          assuranceCompletePrice={assuranceCompletePrice}
                          equipmentBedPrice={equipmentBedPrice}
                />
            </div>

            <div className="flex justify-center">
                <button onClick={handleConfirm} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 mt-4 px-4 rounded">
                    Valider
                </button>
            </div>

            {warningMessage &&
                <p className="text-red-500 mt-2 text-center mt-4">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> {warningMessage}
                </p>
            }
        </div>
    );
}

export default Calendar;
