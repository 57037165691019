import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

function Item({ item, image, isMobileView, link }) {
	const formattedDescription = item.description.split('.').map((sentence, index) => (
		<li key={index}>{sentence.trim()}.</li>
	));

	return (
		<div className={`item w-full h-auto object-cover rounded-lg flex ${isMobileView ? 'flex-col' : 'gap-4'}`}>
			<div className="flex flex-col">
				<h3 className={`item-title text-left ${isMobileView ? 'text-2xl' : 'text-3xl'} font-semibold text-white text-center`}>{item.title}</h3>
				<div className="flex justify-center pt-4">
					<Image src={image} alt={item.title} className="item-image h-48 object-cover rounded-lg" style={{ maxWidth: '350px' }} />
				</div>
			</div>
			<div className="item-description text-white flex-grow flex flex-col justify-center">
				<ul className={`${!isMobileView ? 'px-16' : 'px-0'} m-4 list-disc text-lg`}>
					{formattedDescription}
				</ul>
				<div className="mt-4 flex justify-center">
					<Link to={link} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 text-lg" style={{ fontSize: '1rem' }}>
						Réserver
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Item;
