import React from 'react';
import LightBackground from '../../components/LightBackground';
import Banner from '../../components/Banner';

/* Images */
import nightImage from '../../images/night.jpeg';

function NotFound() {
    return (
        <div className="notFound relative">
            <LightBackground />

            <div className="my-10">
                <Banner title="Erreur 404" description="La page que vous cherchez n'existe pas ou n'est pas disponible" buttonText="Revenir sur la page d'accueil" buttonLink="/" image={nightImage} />
            </div>
        </div>
    )
}

export default NotFound;
