import React from 'react';
import { Link } from 'react-router-dom';
import { useIsMobileView } from '../hooks/useIsMobileView';
import Contact from './Contact';

function Footer() {
  const isMobileView = useIsMobileView();

  return (
    <footer className="max-w-[1920px] mx-auto">
      <div className="my-10">
          <Contact />
      </div>

      <div className={`${isMobileView ? 'm-3 p-5 mt-10' : 'mx-10 p-10'} flex flex-col items-center rounded-lg bg-gray-900 text-white py-8 bg-gray-900 bg-opacity-50 backdrop-blur-lg`}>
        <div className="flex flex-col md:flex-row justify-between w-full mb-4">
          <div className="mb-4 md:mb-0">
            <h4 className="text-lg font-semibold">C' Location</h4>
            <p className="text-sm">Votre partenaire pour des aventures inoubliables en van aménagé.</p>
          </div>
          <div className="flex flex-col md:flex-row gap-4">
            <ul className={`flex ${isMobileView ? 'flex-col' : 'flex-row'} gap-2 ${isMobileView ? 'ml-8' : 'ml-0'}`} style={{ listStyle: isMobileView ? 'disc' : 'none' }}>
              <li><Link to="/rent" className="text-sm hover:underline">Réserver</Link></li>
              <li><Link to="/about" className="text-sm hover:underline">À propos</Link></li>
            </ul>
          </div>
        </div>
        <div className="text-sm">
          &copy; {new Date().getFullYear()} C' Location. Tous droits réservés.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
