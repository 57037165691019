import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIsMobileView } from '../hooks/useIsMobileView';

function Navbar() {
	const isMobileView = useIsMobileView();
	const [isLoggedIn, setIsLoggedIn] = React.useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [confirmationCode, setConfirmationCode] = React.useState('');
	const [isCodeSent, setIsCodeSent] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const location = useLocation();

	const handleSendCode = () => {
		if (email) {
			fetch('http://localhost:5000/send-code', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email }),
			})
			.then(response => {
				if (!response.ok) {
					return response.text().then(text => { throw new Error(text) });
				}
				return response.json();
			})
			.then(data => {
				setIsCodeSent(true);
				alert(data.message);
			})
			.catch(error => {
				setErrorMessage(error.message);
			});
		}
	};

	const handleLogin = () => {
		if (email && password && confirmationCode) {
			fetch('/login', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email, password, confirmationCode }),
			})
			.then(response => response.json())
			.then(data => {
				if (data.message) {
					setIsLoggedIn(true);
					alert(data.message);
				} else {
					setErrorMessage('Erreur lors de la connexion');
				}
			})
			.catch(error => {
				setErrorMessage('Erreur lors de la connexion');
			});
		}
	};

	const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

	const path = location.pathname.split('/')[1];

	const renderMenuItems = () => (
		<>
			<li className={isMobileView ? "mt-2" : ""}>
				<Link to="/" className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${path === '' ? 'text-white bg-blue-700 md:text-blue-700 md:text-blue-500' : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700'}`} aria-current="page" style={{ fontSize: '1rem' }}>Accueil</Link>
			</li>
			<li className={isMobileView ? "mt-2" : ""}>
				<Link to="/rent" className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${path === 'rent' ? 'text-white bg-blue-700 md:text-blue-700 md:text-blue-500' : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700'}`} style={{ fontSize: '1rem' }}>Louer</Link>
			</li>
			<li className={isMobileView ? "mt-2" : ""}>
				<Link to="/about" className={`block py-2 px-3 rounded md:bg-transparent md:p-0 ${path === 'about' ? 'text-white bg-blue-700 md:text-blue-700 md:text-blue-500' : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent border-gray-700'}`} style={{ fontSize: '1rem' }}>À propos</Link>
			</li>
		</>
	);

	const handleEmailPopup = () => {
		const popup = document.createElement('div');
		popup.className = `fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-lg z-50`;
		popup.innerHTML = `
			<div class="rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-lg p-6 max-w-[700px] w-full mx-4 text-white">
				<h2 class="text-2xl font-bold mb-4">Connexion</h2>
				<p class="mb-4">Veuillez entrer votre email et mot de passe pour recevoir un code de confirmation.</p>
				<input type="email" id="email" class="w-full p-2 border border-gray-300 rounded mb-4 bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Votre email" />
				<input type="password" id="password" class="w-full p-2 border border-gray-300 rounded mb-4 bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Votre mot de passe" />
				<div id="errorMessages" class="text-red-500 mb-4"></div>
				<div class="flex justify-end">
					<button id="cancelButton" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2">Annuler</button>
					<button id="sendCodeButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Envoyer</button>
				</div>
			</div>
		`;

		document.body.appendChild(popup);

		document.getElementById('cancelButton').addEventListener('click', () => {
			document.body.removeChild(popup);
		});

		document.getElementById('sendCodeButton').addEventListener('click', () => {
			const emailInput = document.getElementById('email').value;
			const passwordInput = document.getElementById('password').value;
			const errorMessages = document.getElementById('errorMessages');
			errorMessages.innerHTML = '';

			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(emailInput)) {
				errorMessages.innerHTML += '<p class="text-red-500">Veuillez entrer un email valide.</p>';
				document.getElementById('email').classList.add('border-red-500');
			} else {
				document.getElementById('email').classList.remove('border-red-500');
			}

			if (!passwordInput) {
				errorMessages.innerHTML += '<p class="text-red-500">Veuillez entrer votre mot de passe.</p>';
				document.getElementById('password').classList.add('border-red-500');
			} else {
				document.getElementById('password').classList.remove('border-red-500');
			}

			if (emailRegex.test(emailInput) && passwordInput) {
				setEmail(emailInput);
				setPassword(passwordInput);
				handleSendCode();
				document.body.removeChild(popup);
				handleConfirmLogin();
			}
		});
	};

	const handleConfirmLogin = () => {
		const popup = document.createElement('div');
		popup.className = `fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-lg z-50`;
		popup.innerHTML = `
			<div class="rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-lg p-6 max-w-[700px] w-full mx-4 text-white">
				<h2 class="text-2xl font-bold mb-4">Connexion</h2>
				<p class="mb-4">Un code de confirmation a été envoyé à votre email si celui-ci existe dans notre base de données.</p>
				<p class="mb-4">Veuillez entrer votre code de confirmation pour vous connecter.</p>
				<input type="text" id="confirmationCode" class="w-full p-2 border border-gray-300 rounded mb-4 bg-gray-900 bg-opacity-50 backdrop-blur-lg" placeholder="Code de confirmation" />
				<div id="errorMessages" class="text-red-500 mb-4"></div>
				<div class="flex justify-between mt-4">
					<button id="backButton" class="hover:underline text-white font-bold py-2 px-4 rounded flex items-center">
						<svg class="mr-2 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="4">
							<path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
						</svg> Retour
					</button>
					<div class="flex">
						<button id="cancelButton" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2">Annuler</button>
						<button id="confirmButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Confirmer</button>
					</div>
				</div>
			</div>
		`;

		document.body.appendChild(popup);

		document.getElementById('cancelButton').addEventListener('click', () => {
			document.body.removeChild(popup);
		});

		document.getElementById('confirmButton').addEventListener('click', () => {
			const emailInput = document.getElementById('email').value;
			const confirmationCodeInput = document.getElementById('confirmationCode').value;
			const errorMessages = document.getElementById('errorMessages');
			errorMessages.innerHTML = '';

			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(emailInput)) {
				errorMessages.innerHTML += '<p class="text-red-500">Veuillez entrer un email valide.</p>';
				document.getElementById('email').classList.add('border-red-500');
			} else {
				document.getElementById('email').classList.remove('border-red-500');
			}

			if (!confirmationCodeInput) {
				errorMessages.innerHTML += '<p class="text-red-500">Veuillez entrer le code de confirmation.</p>';
				document.getElementById('confirmationCode').classList.add('border-red-500');
			} else {
				document.getElementById('confirmationCode').classList.remove('border-red-500');
			}

			if (emailRegex.test(emailInput) && confirmationCodeInput) {
				setEmail(emailInput);
				setConfirmationCode(confirmationCodeInput);
				handleLogin();
				document.body.removeChild(popup);
			}
		});

		document.getElementById('backButton').addEventListener('click', () => {
			document.body.removeChild(popup);
			handleEmailPopup();
		});
	};

	return (
		<div className="max-w-[1920px] mx-auto">
			<nav className={`relative bg-gray-900 transition-transform duration-300 p-2 ${isMobileView ? 'm-3' : 'mx-10'} bg-opacity-50 backdrop-blur-lg max-w-[1920px]`} style={{ borderRadius: '10px' }}>
				<div className="w-full h-50 flex flex-wrap items-center justify-between p-1 bg-opacity-50 backdrop-blur-lg">
					<Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse pl-3">
						<span className="self-center text-2xl font-semibold whitespace-nowrap text-white" style={{ fontSize: '2rem' }}>C' Location</span>
					</Link>
					<div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
						<button onClick={toggleMobileMenu} data-collapse-toggle="navbar-user" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600" aria-controls="navbar-user" aria-expanded={isMobileMenuOpen}>
							<span className="sr-only">Open main menu</span>
							<svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
							</svg>
						</button>
					</div>
					{!isMobileView && (
						<div className={`items-center justify-between w-full md:flex md:w-auto md:order-1`} id="navbar-user" style={{ alignItems: 'center' }}>
							<ul className="flex flex-col font-medium p-6 md:p-0 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 border-gray-700 items-center">
								{renderMenuItems()}
							</ul>
						</div>
					)}
				</div>
				{isMobileView && (
					<div className={`items-center justify-between ${isMobileMenuOpen ? 'block' : 'hidden'} w-full md:flex md:w-auto md:order-1`} id="navbar-user" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: '10px' }}>
						<ul className="flex flex-col font-medium p-6 md:p-0 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 border-gray-700">
							{renderMenuItems()}
						</ul>
					</div>
				)}
			</nav>
		</div>
	);
}

export default Navbar;
