import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { useIsMobileView } from '../hooks/useIsMobileView';

/* Images */
import profile1 from '../images/papa.webp';
import profile2 from '../images/head1.jpg';
import profile3 from '../images/head2.jpg';

const reviews = [
    {
        profilePic: profile1,
        reviewText: 'Super expérience, le van était très confortable et bien équipé.',
        rating: 5
    },
    {
        profilePic: profile2,
        reviewText: 'Nous avons passé un excellent week-end grâce à ce van.',
        rating: 5
    },
    {
        profilePic: profile3,
        reviewText: 'Le van était parfait pour notre road trip, je recommande !',
        rating: 5
    }
];

function Review() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const isMobileView = useIsMobileView();

    const handleLeftClick = () => {
        setSelectedIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };

    const handleRightClick = () => {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            handleRightClick();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="max-w-[1920px] mx-auto">
            <div className={`review-container ${isMobileView ? 'm-3 p-5' : 'mx-10 p-10'} rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg flex-row`} tabIndex="0">
                <h3 className="text-white text-2xl font-bold mb-4" style={{ fontSize: '2rem' }}>Avis des clients</h3>
                <div className={`flex items-center flex-row ${isMobileView ? 'gap-2' : 'gap-4'}`}>
                    <button onClick={handleLeftClick} className="text-white bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-full p-2 hover:bg-gray-800 w-12 h-12 min-w-12 min-h-12">
                        <FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4 min-w-4 min-h-4" />
                    </button>
                    <div className="overflow-hidden flex-grow max-w-[1000px] mx-auto">
                        <div className="flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${selectedIndex * 100}%)` }}>
                            {reviews.map((review, index) => (
                                <div key={index} className={`review-card ${isMobileView ? 'p-5' : 'p-10'}  flex-shrink-0 w-full`}>
                                    <div className="flex flex-col items-center rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg px-0 py-10">
                                        <img src={review.profilePic} alt="Profile" className="w-16 h-16 rounded-full mb-4" />
                                        <p className="text-white text-center mb-4">{review.reviewText}</p>
                                        <div className="flex">
                                            {[...Array(5)].map((star, i) => (
                                                <FontAwesomeIcon
                                                    key={i}
                                                    icon={faStar}
                                                    className={`w-4 h-4 ${i < review.rating ? 'text-yellow-500 hover:text-yellow-400 hover:scale-110' : 'text-gray-500'}`}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button onClick={handleRightClick} className="text-white bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-full p-2 hover:bg-gray-800 w-12 h-12 min-w-12 min-h-12">
                        <FontAwesomeIcon icon={faChevronRight} className="w-4 h-4 min-w-4 min-h-4" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Review;
