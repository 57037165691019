import React, { useState, useRef, useEffect } from 'react';
import { Image } from 'antd';
import { useIsMobileView, useIsMobileViewStrict } from '../hooks/useIsMobileView';

const Galerie = ({ images }) => {
  const isMobileView = useIsMobileView();
  const isMobileViewStrict = useIsMobileViewStrict();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleImages, setVisibleImages] = useState([]);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    updateVisibleImages();
    window.addEventListener('resize', updateVisibleImages);
    return () => window.removeEventListener('resize', updateVisibleImages);
  }, [images]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    scrollToImage(currentIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    scrollToImage(currentIndex - 1);
  };

  const handleSelect = (index) => {
    setCurrentIndex(index);
    scrollToImage(index);
  };

  const updateVisibleImages = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const containerWidth = container.offsetWidth;
    const imageWidth = isMobileViewStrict ? 100 : 200;
    const visibleCount = Math.floor(containerWidth / imageWidth);
    setVisibleImages(images.slice(0, visibleCount));
  };

  const scrollToImage = (index) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const imageElement = container.children[index];
    if (imageElement) {
      const scrollLeft = imageElement.offsetLeft - container.offsetWidth / 2 + imageElement.offsetWidth / 2;
      container.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className={`w-full`}>
      <div className={`rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg ${!isMobileView ? "mx-10 p-10" : "m-3 p-3"}`}>
        <h3 className="text-white text-2xl font-bold mt-4" style={{ fontSize: isMobileView ? '1.5rem' : '2rem', textAlign: isMobileView ? 'center' : 'left' }}>Galerie</h3>
        <div className="relative flex justify-center items-center">
          <button onClick={handlePrev} className="absolute left-0 bg-gray-900 bg-opacity-50 text-white p-2 rounded-full z-10 ml-2">
            <svg className={`${isMobileView ? 'w-4 h-4' : 'w-6 h-6'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
            </svg>
          </button>
          <div className={`rounded-lg overflow-hidden w-full ${isMobileView ? 'h-[300px]' : 'h-[40rem]'} flex justify-center items-center mt-4`}>
            <Image src={images[currentIndex]} className="rounded-lg transition-opacity duration-500 ease-in-out w-full h-auto object-cover" />
          </div>
          <button onClick={handleNext} className="absolute right-0 bg-gray-900 bg-opacity-50 text-white p-2 rounded-full z-10 mr-2">
            <svg className={`${isMobileView ? 'w-4 h-4' : 'w-6 h-6'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
        <div ref={scrollContainerRef} className="flex justify-start mt-4 overflow-x-auto">
          {images.map((src, index) => (
            <div key={index} className="mx-1 flex-shrink-0">
              <img
                src={src}
                className={`rounded-lg cursor-pointer ${isMobileViewStrict ? 'w-[100px] h-[75px]' : 'w-[200px] h-[150px]'} object-cover mb-4 ${index === currentIndex ? 'border-2 border-blue-700 scale-95' : ''}`}
                onClick={() => handleSelect(index)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Galerie;
