import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { PlaneGeometry, MeshStandardMaterial, CircleGeometry } from 'three';
import { extend } from '@react-three/fiber';
import { useIsMobileView } from '../hooks/useIsMobileView';

extend({ PlaneGeometry, MeshStandardMaterial, CircleGeometry });

const AnimatedDisks = () => {
	const disksRef = useRef([]);
	const isMobileView = useIsMobileView();

	const disks = useMemo(() => {
		return Array.from({ length: isMobileView ? 5 : 20 }, (_, i) => ({
			key: i,
			position: [
				Math.random() * 10 - 5,
				Math.random() * 10 - 5,
				Math.random() * 10 - 5
			],
			color: `rgb(0, 0, ${Math.floor(Math.random() * 128) + 128})`,
			radius: Math.random() * (isMobileView ? 3 : 1)
		}));
	}, [isMobileView]);

	useFrame(({ clock }) => {
		const elapsed = clock.getElapsedTime();

		if (isMobileView) {
			if (Math.floor(elapsed * 10) % 2 === 0) {
				disksRef.current.forEach((disk, i) => {
					if (disk) {
						disk.position.x += Math.sin(i + elapsed) * 0.01;
						disk.position.y += Math.cos(i + elapsed) * 0.01;
						disk.position.z += Math.sin(i + elapsed) * 0.01;
					}
				});
			}
		} else {
			disksRef.current.forEach((disk, i) => {
				if (disk) {
					disk.position.x += Math.sin(i + elapsed) * 0.01;
				}
			});
		}
	});

	return (
		<>
			{disks.map((disk, i) => (
				<mesh
					key={disk.key}
					ref={(el) => (disksRef.current[i] = el)}
					position={disk.position}
				>
					<circleGeometry attach="geometry" args={[disk.radius, 32]} />
					<meshStandardMaterial attach="material" color={disk.color} />
				</mesh>
			))}
		</>
	);
};

const LightBackground = () => {
	const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
	const canvasRef = useRef();
	const isMobileView = useIsMobileView();

	useEffect(() => {
		const handleResize = () => {
			setDimensions({ width: window.innerWidth, height: window.innerHeight });

			if (canvasRef.current) {
				const gl = canvasRef.current.getContext('webgl');

				if (gl) {
					gl.getExtension('WEBGL_lose_context').loseContext();
				}
			}
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Canvas ref={canvasRef} style={{ width: dimensions.width + (isMobileView ? 100 : 200), height: dimensions.height + (isMobileView ? 100 : 200), filter: 'blur(100px)', position: 'fixed', top: -100, left: -100, zIndex: -1, backgroundColor: 'rgba(0, 0, 255, 0.035)' }}>
			<ambientLight intensity={1} />
			<AnimatedDisks />
		</Canvas>
	);
};

export default LightBackground;
