import React from 'react';
import LightBackground from '../../components/LightBackground';
import Banner from '../../components/Banner';
import Text from '../../components/Text';

/* Images */
import aboutImage from '../../images/night.jpeg';

function About() {
    return (
        <div className="about relative">
            <LightBackground />
            <div className="my-10">
                <Banner title="À propos de nous" description="Découvrez notre histoire et notre mission" image={aboutImage} />
            </div>
            <div className="my-10">
                <Text text="Chez C' Location, nous sommes passionnés par les voyages et l'aventure.
                        Notre mission est de vous offrir la liberté de découvrir le monde à votre rythme, avec tout le confort nécessaire.
                        Nous proposons une sélection de vans aménagés, équipés pour répondre à tous vos besoins, afin que vous puissiez profiter pleinement de chaque instant de votre voyage.
                        Rejoignez-nous et embarquez pour une aventure inoubliable" />
            </div>
        </div>
    );
}

export default About;
