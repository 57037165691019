import React from 'react';
import { useIsMobileView } from '../hooks/useIsMobileView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const SimPrice = ({ diffDays, price, extraKm, extraKmPrice, equipmentBed, selectedAssurance, equipmentCabine, assuranceIntermediatePrice, assuranceCompletePrice, equipmentCale, equipmentMenage, equipmentCalePrice, equipmentCabinePrice, equipmentMenagePrice, equipmentBedPrice }) => {
    const isMobileView = useIsMobileView();
    const pricePerDay = price;
    const totalPrice = diffDays * pricePerDay + (isNaN(Number(extraKmPrice)) ? 0 : Number(extraKmPrice)) + ((selectedAssurance === "intermediaire" ? assuranceIntermediatePrice : (selectedAssurance === "complete" ? assuranceCompletePrice : 0)) * diffDays) + (equipmentMenage ? equipmentMenagePrice : 0) + (equipmentCabine ? equipmentCabinePrice : 0) + (equipmentCale ? equipmentCalePrice : 0) + ((equipmentBed ? equipmentBedPrice : 0) * equipmentBed);
    const caution = 2000;
    const equipmentBedPriceTotal = ((equipmentBed ? equipmentBedPrice : 0) * equipmentBed);

    return (
        <div className="max-w-[1920px] mx-auto">
            <div className={`flex ${!isMobileView ? "mx-10 p-10 gap-10 flex-row" : "m-3 my-0 p-10 gap-10 flex-col"} justify-center items-center overflow-x-auto rounded-lg bg-gray-900 bg-opacity-50 backdrop-blur-lg`}>
                <div className="flex flex-col items-center mx-4 min-w-[100px]">
                    <p className="text-white text-sm text-center">Prix de la location : {pricePerDay * diffDays}€</p>
                </div>
                {Number(extraKm) > 0 && (
                    <>
                        <div className="w-full border-b border-gray-700 my-2 max-w-[200px] min-w-[20px]"></div>
                        <div className="flex flex-col items-center mx-4 min-w-[100px]">
                            <p className="text-white text-sm text-center">Supplement.s de Kilomètre.s : {extraKmPrice}€</p>
                        </div>
                    </>
                )}
                {selectedAssurance !== 'base' && diffDays > 0 && (
                    <>
                        <div className="w-full border-b border-gray-700 my-2 max-w-[200px] min-w-[20px]"></div>
                        <div className="flex flex-col items-center mx-4 min-w-[100px]">
                            <p className="text-white text-sm text-center">Assurance : {(selectedAssurance === "intermediaire" ? assuranceIntermediatePrice : assuranceCompletePrice) * diffDays}€</p>
                        </div>
                    </>
                )}
                {(equipmentMenage || equipmentCabine || equipmentCale || equipmentBedPriceTotal > 0) && (
                    <>
                        <div className="w-full border-b border-gray-700 my-2 max-w-[200px] min-w-[20px]"></div>
                        <div className="flex flex-col items-center mx-4 min-w-[100px]">
                            <p className="text-white text-sm text-center">Equipement.s : {(equipmentMenage ? equipmentMenagePrice : 0) + (equipmentCabine ? equipmentCabinePrice : 0) + (equipmentCale ? equipmentCalePrice : 0) + equipmentBedPriceTotal}€</p>
                        </div>
                    </>
                )}
                <div className="w-full border-b border-gray-700 my-2 max-w-[200px] min-w-[20px]"></div>
                <div className="flex flex-col items-center mx-4 min-w-[100px]">
                    <p className="text-white text-sm text-center flex items-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-white" />
                        Caution : {caution}€
                    </p>
                </div>
                {diffDays > 0 && (
                    <>
                        <div className="w-full border-b border-gray-700 my-2 max-w-[200px] min-w-[20px]"></div>
                        <div className="flex flex-col items-center mx-4 min-w-[100px]">
                            <p className="text-white text-sm text-center flex items-center">
                                Total : {totalPrice}€
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SimPrice;
